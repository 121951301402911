import { defineStore } from 'pinia'
import { IUser, User } from '@/js/utils/types'
import store from 'store'
import { computed, ref } from 'vue'
import { get } from '@/js/utils/helpers'

export const clearUserLocalStorage = () => {
    console.warn('[UserState] User Clear')
    store.remove('user')
    store.remove('user_refresh_at')
}

const useUserStore = defineStore('UserStore', () => {
    const user = ref<User | undefined>(store.get('user') ? new User(store.get('user')) : undefined)
    const refreshAt = ref(store.get('user_refresh_at'))

    const isLogged = computed(() => typeof user.value?.id !== 'undefined')

    const updateRefresh = () => {
        const now = new Date()
        refreshAt.value = new Date(now.getTime() + import.meta.env.VITE_REFRESH_USER_MINUTES * 60000).getTime()
    }

    const setUser = (data: IUser) => {
        console.warn('[UserState] User Set', data)

        user.value = new User(data)
        updateRefresh()

        store.set('user', data)
        store.set('user_refresh_at', refreshAt.value)
    }

    const clearUser = () => {
        clearUserLocalStorage()
        user.value = undefined
        refreshAt.value = undefined
    }

    const tryAutoLogin = () => {
        const now = new Date()
        const refreshDate = new Date(refreshAt.value)

        if (now.getTime() >= refreshDate.getTime()) {
            get<IUser>('profile').then((data) => {
                console.warn('[UserState] User Refresh', data)
                setUser(data)
                updateRefresh()
            })
        }
    }

    return {
        user,
        isLogged,
        setUser,
        clearUser,
        tryAutoLogin,
    }
})

export default useUserStore
