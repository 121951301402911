import { useMutation as useMutationMumble } from '@mumble/hooks'
import useUser from '@/js/hooks/useUser'
import { UseMutationOptions } from '@mumble/hooks/vue/useMutation'

export default function useMutation(api: (data: any) => Promise<any>, options?: UseMutationOptions) {
    const { user } = useUser()

    return useMutationMumble(api, {
        ...options,
        logOptions: {
            user: user.value,
            type: 'api',
        },
    })
}
