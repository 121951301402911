import { API, MumbleApiResponse } from '@mumble/api'
import { ApiResponse } from '@/js/utils/types'
import { clearUserLocalStorage } from '@/js/store/UserStore'
import router from '@/js/router'
export const apiResponseHandler = <TData>(response: MumbleApiResponse<ApiResponse<TData>>): Promise<TData> => {
    return Promise.resolve(response?.data.body)
}

const apiHelpers = new API('/api/', {
    enableCredentials: true,
    onUnauthorized: () => {
        if (!window.location.href.includes('accedi')) {
            clearUserLocalStorage()
            window.location.href = '/accedi'
        }
    },
    onIntercept: (errorResponse) => {
        if (errorResponse?.status === 404) {
            router.push({ name: 'not-found' })
        }
    },
})

const apiFormData = new API('/api/', {
    enableCredentials: true,
    accept: 'application/json',
    contentType: 'multipart/form-data',
    onUnauthorized: () => {
        if (!window.location.href.includes('accedi')) {
            clearUserLocalStorage()
            window.location.href = '/accedi'
        }
    },
    onIntercept: (errorResponse) => {
        if (errorResponse?.status === 404) {
            router.push({ name: 'not-found' })
        }
    },
})

export const get = <TData>(
    url: string,
    opts?: {
        params?: any
        returnFullResponse?: boolean
        isFormData?: boolean
    }
) => {
    const params = opts?.params ?? undefined
    const returnFullResponse = opts?.returnFullResponse ?? false
    const isFormData = opts?.isFormData ?? false
    const instance = isFormData ? apiFormData : apiHelpers

    return returnFullResponse
        ? instance.get(url, { config: { params } }).then((res) => res.data)
        : instance.get(url, { config: { params } }).then(apiResponseHandler<TData>)
}

export const post = <TData>(
    url: string,
    data?: any,
    opts?: {
        returnFullResponse?: boolean
        isFormData?: boolean
    }
) => {
    const returnFullResponse = opts?.returnFullResponse ?? false
    const isFormData = opts?.isFormData ?? false
    const instance = isFormData ? apiFormData : apiHelpers

    return returnFullResponse
        ? instance.post(url, { data }).then((res) => res.data)
        : instance.post(url, { data }).then(apiResponseHandler<TData>)
}

export const fetchDelete = <TData>(
    url: string,
    params?: any,
    opts?: {
        returnFullResponse?: boolean
        isFormData?: boolean
    }
) => {
    const returnFullResponse = opts?.returnFullResponse ?? false
    const isFormData = opts?.isFormData ?? false
    const instance = isFormData ? apiFormData : apiHelpers

    return returnFullResponse
        ? instance.delete(url, { config: { params } }).then((res) => res.data)
        : instance.delete(url, { config: { params } }).then(apiResponseHandler<TData>)
}
