import { Validations } from '@mumble/hooks/types/validations.types'
import { FormLogin, FormPasswordForgot, FormPasswordReset } from '@/js/utils/types'

export const formLoginInitialValues: FormLogin = {
    email: '',
    password: '',
}
export const formLoginUpdateValidations: Validations<FormLogin> = {
    email: {
        required: true,
        isEmail: true,
    },
    password: {
        required: true,
        minLength: 8,
    },
}

export const formPasswordForgotInitialValues: FormPasswordForgot = {
    email: '',
}
export const formPasswordForgotUpdateValidations: Validations<FormPasswordForgot> = {
    email: {
        required: true,
        isEmail: true,
    },
}

export const formPasswordResetInitialValues: FormPasswordReset = {
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
}
export const formPasswordResetValidations: Validations<FormPasswordReset> = {
    old_password: {
        required: true,
        minLength: 8,
    },
    new_password: {
        required: true,
        minLength: 8,
    },
    new_password_confirmation: {
        required: true,
        confirm: 'new_password',
        confirmLabel: 'password',
    },
}
