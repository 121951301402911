import { reactive } from 'vue'

export default function useDialog<T>() {
    const dialog = reactive<{
        isOpen: boolean
        data?: T
        show: () => void
        hide: () => void
        set: (data: T) => void
        close: () => void
    }>({
        isOpen: false,
        data: undefined,
        show: () => {
            dialog.isOpen = true
        },
        hide: () => {
            dialog.isOpen = false
        },
        set: (data: T) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dialog.data = data
        },
        close: () => {
            dialog.isOpen = false
            dialog.data = undefined
        },
    })

    return dialog
}
