import { createApp } from 'vue'
import router from '@/js/router'
import { VueQueryPlugin } from '@tanstack/vue-query'
import App from '@/views/Layout/App.vue'

import { MInput, MGrid, MGridColumn } from '@mumble/components'
import PureButton from '@/js/components/Pure/PureButton.vue'
import Alert from '@/js/components/Alert.vue'
import { init } from '@mumble/hooks/vue/useSentry'

import '@/sass/app.scss'
import { createPinia } from 'pinia'

if (import.meta.env.PROD) {
    console.log = () => null
    console.table = () => null
}

const pinia = createPinia()

const app = createApp(App)

init({
    app,
    dsn: 'https://edf5c1bda59f1dd56d88730d35224050@o429735.ingest.sentry.io/4506156552028160',
})

app.component('Alert', Alert)
app.component('MInput', MInput)
app.component('PureButton', PureButton)
app.component('MGrid', MGrid)
app.component('MGridColumn', MGridColumn)

app.use(VueQueryPlugin).use(router).use(pinia)

app.mount('#app')
