import { computed, ComputedRef } from 'vue'
import useUserStore from '@/js/store/UserStore'
import { IUser, User } from '@/js/utils/types'

export default function useUser() {
    const store = useUserStore()
    const user = computed(() => store.user)
    const isLogged = computed(() => store.isLogged)
    const isAdmin = computed(() => user.value?.isAdmin() ?? false)
    const isCollaborator = computed(() => user.value?.isCollaborator() ?? false)
    const isHolding = computed(() => user.value?.isHolding() ?? false)
    const isCompany = computed(() => user.value?.isCompany() ?? false)
    const hasPermissions = computed(() => user.value?.hasPermissions() ?? false)

    return {
        user: user as ComputedRef<User>,
        isLogged,
        isAdmin,
        isCollaborator,
        isCompany,
        isHolding,
        hasPermissions,
        setUser: (data: IUser) => store.setUser(data),
        clearUser: () => store.clearUser(),
        tryAutoLogin: store.tryAutoLogin,
    }
}
