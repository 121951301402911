<script setup lang="ts">
import Logo from '@/js/components/Logo.vue'
import Card from '@/js/components/Card/Card.vue'
import { useValidations } from '@mumble/hooks'
import { formLoginInitialValues, formLoginUpdateValidations } from '@/js/utils/constants'
import { useLogin } from '@/js/hooks/api/useAuth'
import { unref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { mutate, alert, isLoading } = useLogin()
const { form, errors, hasErrors } = useValidations(formLoginInitialValues, formLoginUpdateValidations, { locale: 'it' })
const submit = () => {
    const data = {
        ...unref(form),
        code: unref(route.query.code),
    }

    mutate(unref(data))
}
</script>

<template>
    <div class="text-center">
        <Logo class="mx-auto mb-6" />
        <h1 class="h2 mb-0">Accedi al tuo profilo</h1>
        <p class="mb-6">Inserisci le tue credenziali per accedere.</p>

        <div class="text-left flex justify-center">
            <Card>
                <Alert :alert="alert" />
                <form @submit.prevent="submit">
                    <MInput
                        v-model:value="form.email"
                        :error="errors.email"
                        type="email"
                        focus
                        class="mb-6"
                        label="Email"
                        placeholder="nome.cognome@mail.com"
                        autocomplete="email"
                    />
                    <MInput
                        v-model:value="form.password"
                        :error="errors.password"
                        type="password"
                        class="mb-6"
                        label="Password"
                        placeholder="**************"
                        autocomplete="current-password"
                    />
                    <router-link :to="{ name: 'forgot-password' }" class="block text-right text-sm mb-6">
                        Hai dimenticato la password?
                    </router-link>
                    <PureButton lg :loading="isLoading" :disabled="hasErrors" primary submit full-width> Accedi </PureButton>
                </form>
            </Card>
        </div>
    </div>
</template>
