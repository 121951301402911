import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
declare module 'vue-router' {
    interface RouteMeta {
        isProtected?: boolean
        hasGradientHeader?: boolean
        // layout?: 'auth'
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // interface RouteParams {}
}
import Home from '@/views/Pages/Home.vue'
import NotFound from '@/views/Pages/NotFound.vue'
import Login from '@/views/Pages/Auth/Login.vue'
import useUser from '@/js/hooks/useUser'
const ForgotPassword = () => import('@/views/Pages/Auth/PasswordForgot.vue')
const Profile = () => import('@/views/Pages/Profile.vue')
const HoldingList = () => import('@/views/Pages/Holding/HoldingList.vue')
const HoldingEdit = () => import('@/views/Pages/Holding/HoldingEdit.vue')
const CompanyList = () => import('@/views/Pages/Company/CompanyList.vue')
const CompanyEdit = () => import('@/views/Pages/Company/CompanyEdit.vue')
const MeterList = () => import('@/views/Pages/Meter/MeterList.vue')
const MeterEdit = () => import('@/views/Pages/Meter/Meter.vue')
const Dashboard = () => import('@/views/Pages/Dashboard.vue')
const CollaboratorList = () => import('@/views/Pages/Collaborator/CollaboratorList.vue')
const CollaboratorEdit = () => import('@/views/Pages/Collaborator/CollaboratorEdit.vue')
const Contracts = () => import('@/views/Pages/Contracts.vue')

const routes: RouteRecordRaw[] = [
    // TODO remove redirect when dashboard will be defined
    { path: '/', component: Home, name: 'home', meta: { isProtected: true }, redirect: { name: 'company-list' } },
    { path: '/accedi', component: Login, name: 'login', meta: { isProtected: false } },
    { path: '/contatori', component: MeterList, name: 'meter-list', meta: { isProtected: true } },
    { path: '/aziende', component: CompanyList, name: 'company-list', meta: { isProtected: true } },
    { path: '/aziende/:id', component: CompanyEdit, name: 'company-edit', meta: { isProtected: true } },
    {
        path: '/aziende/:companyId/contatori/:meterId',
        component: MeterEdit,
        name: 'meter',
        meta: { isProtected: true },
    },
    { path: '/holding', component: HoldingList, name: 'holding-list', meta: { isProtected: true } },
    { path: '/holding/:id', component: HoldingEdit, name: 'holding-edit', meta: { isProtected: true } },
    { path: '/collaboratori', component: CollaboratorList, name: 'collaborator-list', meta: { isProtected: true } },
    { path: '/collaboratori/:id', component: CollaboratorEdit, name: 'collaborator-edit', meta: { isProtected: true } },
    { path: '/recupera-password', component: ForgotPassword, name: 'forgot-password', meta: { isProtected: false } },
    { path: '/profilo', component: Profile, name: 'profile', meta: { isProtected: true } },
    {
        path: '/aziende/:id/dashboard',
        component: Dashboard,
        name: 'company-dashboard',
        meta: { isProtected: true, hasGradientHeader: true },
    },
    {
        path: '/holding/:id/dashboard',
        component: Dashboard,
        name: 'holding-dashboard',
        meta: { isProtected: true, hasGradientHeader: true },
    },
    { path: '/contratti', component: Contracts, name: 'contracts-list', meta: { isProtected: true } },
    { path: '/:pathMatch(.*)*', component: NotFound, name: 'not-found' },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const { isLogged, tryAutoLogin } = useUser()
    tryAutoLogin()

    if (to.meta.isProtected && !isLogged.value) {
        next({ name: 'login' })
    } else if ((to.name === 'login' || to.name === 'forgot-password') && isLogged.value) {
        next({ name: 'home' })
    } else {
        next()
    }
})

router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
        window.location.href = to.fullPath
    }
})

export default router
