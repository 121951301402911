<script setup lang="ts">
import Logo from '@/js/components/Logo.vue'
import { StoreIcon, Users, LogOutIcon, UserIcon, LandmarkIcon, TrendingUpIcon, WalletCardsIcon } from 'lucide-vue-next'
import useUser from '@/js/hooks/useUser'
import { useLogout } from '@/js/hooks/api/useAuth'
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import { MDialog } from '@mumble/components/index'
import useDialog from '@/js/hooks/useDialog'
import MobileMenu from '@/js/components/Layout/MobileMenu.vue'
import MeterIcon from '@/js/components/Meter/MeterIcon.vue'
import ButtonNavigation from '@/js/components/ButtonNavigation.vue'

const route = useRoute()
const { user, isAdmin, isHolding, isCompany, hasPermissions } = useUser()
const image = computed(() => user.value?.image?.url)
const isMobileOpen = ref(false)

const dialogLogout = useDialog()
const { mutate } = useLogout()
const logout = () => {
    mutate(null)
    isMobileOpen.value = false
}

const toggleMobile = () => {
    isMobileOpen.value = !isMobileOpen.value
    isMobileOpen.value
        ? document.querySelector('html')?.classList.add('overflow-hidden')
        : document.querySelector('html')?.classList.remove('overflow-hidden')
}

const logoTo = computed(() => {
    if (user.value?.isCompany()) {
        return { name: 'company-dashboard', params: { id: user.value.companyId } }
    }
    if (user.value?.isHolding()) {
        return { name: 'holding-dashboard', params: { id: user.value.holdingId } }
    }

    return { name: 'home' }
})
</script>

<template>
    <header class="header">
        <router-link :to="logoTo">
            <Logo sm />
        </router-link>
        <nav class="header__nav">
            <template v-if="isCompany">
                <router-link :to="{ name: 'company-dashboard', params: { id: user?.companyId } }">
                    <ButtonNavigation class="mx-4" :active="route.name.includes('dashboard')">
                        <TrendingUpIcon class="w-5 h-5 mr-1.5" />
                        Dashboard
                    </ButtonNavigation>
                </router-link>
                <router-link :to="{ name: 'meter-list' }">
                    <ButtonNavigation class="mx-4" :active="route.name.includes('meter')">
                        <MeterIcon
                            :primary="!route.name.includes('meter')"
                            :white="route.name.includes('meter')"
                            class="w-5 h-5 mr-1.5"
                        />
                        Contatori
                    </ButtonNavigation>
                </router-link>
            </template>
            <router-link v-if="isHolding" :to="{ name: 'holding-dashboard', params: { id: user?.holdingId } }">
                <ButtonNavigation class="mx-4" :active="route.name.includes('holding-dashboard')">
                    <TrendingUpIcon class="w-5 h-5 mr-1.5" />
                    Dashboard
                </ButtonNavigation>
            </router-link>
            <router-link v-if="!isCompany" :to="{ name: 'company-list' }">
                <ButtonNavigation class="mx-4" :active="route.name.includes('company') || route.name.includes('meter')">
                    <StoreIcon class="w-5 h-5 mr-1.5" />
                    Aziende
                </ButtonNavigation>
            </router-link>
            <router-link v-if="hasPermissions" :to="{ name: 'holding-list' }">
                <ButtonNavigation class="mx-4" :active="route.name.includes('holding')">
                    <LandmarkIcon class="w-5 h-5 mr-1.5" />
                    Holding
                </ButtonNavigation>
            </router-link>
            <router-link v-if="isAdmin" :to="{ name: 'collaborator-list' }">
                <ButtonNavigation class="mx-4" :active="route.name.includes('collaborator')">
                    <Users class="w-5 h-5 mr-1.5" />
                    Collaboratori
                </ButtonNavigation>
            </router-link>
            <router-link v-if="isAdmin" :to="{ name: 'contracts-list' }">
                <ButtonNavigation class="mx-4" :active="route.name.includes('contracts')">
                    <WalletCardsIcon class="w-5 h-5 mr-1.5" />
                    Contratti
                </ButtonNavigation>
            </router-link>
        </nav>
        <div class="header__actions">
            <router-link v-if="user" :to="{ name: 'profile' }" class="header__user">
                <div :class="['header__user-icon', { 'p-2.5': !image }]">
                    <div
                        v-if="image"
                        class="w-10 h-10 border-none rounded-full bg-cover bg-center"
                        :style="{ backgroundImage: `url(${image})` }"
                    />
                    <UserIcon v-else class="w-5 h-5" />
                </div>
                {{ user.getFullName() }}
            </router-link>
            <button type="button" class="header__logout" @click="dialogLogout.show()">
                <LogOutIcon class="w-5 h-5" />
            </button>
        </div>

        <button
            type="button"
            :class="[
                'header__burger burger burger-squeeze',
                {
                    open: isMobileOpen,
                },
            ]"
            @click="toggleMobile"
        >
            <span class="burger-lines" />
        </button>

        <MobileMenu :is-open="isMobileOpen" @close="toggleMobile" @logout="dialogLogout.show()" />

        <MDialog :is-open="dialogLogout.isOpen" class="dialog--danger">
            <template #icon>
                <div class="dialog__icon">
                    <UserIcon />
                </div>
            </template>
            <h3 class="h2 mb-2">Vuoi effettuare il Logout?</h3>
            <p>Se effettui il Logout, dovrai accedere nuovamente per poter utilizzare la piattaforma.</p>

            <template #footer>
                <MGrid padding="2" gap="2">
                    <MGridColumn mobile="w-1/2">
                        <PureButton lg full-width @click="() => dialogLogout.hide()"> Chiudi </PureButton>
                    </MGridColumn>
                    <MGridColumn mobile="w-1/2">
                        <PureButton full-width danger lg @click="logout"> Conferma </PureButton>
                    </MGridColumn>
                </MGrid>
            </template>
        </MDialog>
    </header>
</template>
