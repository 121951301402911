<script setup lang="ts">
import { LandmarkIcon, StoreIcon, LogOutIcon, UserIcon, Users, TrendingUpIcon } from 'lucide-vue-next'
import { useRoute } from 'vue-router'
import useUser from '@/js/hooks/useUser'
import { computed } from 'vue'
import ButtonNavigation from '@/js/components/ButtonNavigation.vue'
import MeterIcon from '@/js/components/Meter/MeterIcon.vue'

interface Props {
    isOpen: boolean
}
defineProps<Props>()

const route = useRoute()
const { user, isAdmin, isCollaborator, isCompany } = useUser()
const image = computed(() => user.value?.image?.url)

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'logout'): void
}>()
</script>

<template>
    <Teleport to="body">
        <div class="mobile-menu">
            <transition name="fade">
                <span v-if="isOpen" class="mobile-menu__overlay" />
            </transition>
            <transition name="slide-right">
                <div v-if="isOpen" class="mobile-menu__menu">
                    <nav>
                        <ul class="flex gap-y-5 flex-col items-end">
                            <template v-if="isCompany">
                                <li>
                                    <router-link
                                        :to="{ name: 'company-dashboard', params: { id: user?.companyId } }"
                                        @click="() => emit('close')"
                                    >
                                        <ButtonNavigation class="mx-4" :active="route.name.includes('dashboard')">
                                            <TrendingUpIcon class="w-5 h-5 mr-1.5" />
                                            Dashboard
                                        </ButtonNavigation>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'meter-list' }" @click="() => emit('close')">
                                        <ButtonNavigation class="mx-4" :active="route.name.includes('meter')">
                                            <MeterIcon
                                                :primary="!route.name.includes('meter')"
                                                :white="route.name.includes('meter')"
                                                class="w-5 h-5 mr-1.5"
                                            />
                                            Contatori
                                        </ButtonNavigation>
                                    </router-link>
                                </li>
                            </template>
                            <li>
                                <router-link
                                    v-if="!isCompany"
                                    :to="{ name: 'company-list' }"
                                    @click="() => emit('close')"
                                >
                                    <ButtonNavigation
                                        :active="route.name.includes('company') || route.name.includes('meter')"
                                    >
                                        <StoreIcon class="w-5 h-5 mr-1.5" />
                                        Aziende
                                    </ButtonNavigation>
                                </router-link>
                            </li>
                            <li>
                                <router-link
                                    v-if="isAdmin || isCollaborator"
                                    :to="{ name: 'holding-list' }"
                                    @click="() => emit('close')"
                                >
                                    <ButtonNavigation :active="route.name.includes('holding')">
                                        <LandmarkIcon class="w-5 h-5 mr-1.5" />
                                        Holding
                                    </ButtonNavigation>
                                </router-link>
                            </li>
                            <router-link
                                v-if="isAdmin"
                                :to="{ name: 'collaborator-list' }"
                                @click="() => emit('close')"
                            >
                                <li>
                                    <ButtonNavigation :active="route.name.includes('collaborator')">
                                        <Users class="w-5 h-5 mr-1.5" />
                                        Collaboratori
                                    </ButtonNavigation>
                                </li>
                            </router-link>
                        </ul>
                    </nav>

                    <div class="flex items-center mt-auto justify-end">
                        <router-link
                            v-if="user"
                            :to="{ name: 'profile' }"
                            class="header__user"
                            @click="() => emit('close')"
                        >
                            <div :class="['header__user-icon', { 'p-2.5': !image }]">
                                <div
                                    v-if="image"
                                    class="w-10 h-10 border-none rounded-full bg-cover bg-center"
                                    :style="{ backgroundImage: `url(${image})` }"
                                />
                                <UserIcon v-else class="w-5 h-5" />
                            </div>
                            {{ user.getFullName() }}
                        </router-link>
                        <button type="button" class="header__logout" @click="() => emit('logout')">
                            <LogOutIcon class="w-4 h-4" />
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </Teleport>
</template>
