<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    shadow?: boolean
    sm?: boolean
    xs?: boolean
    stylized?: 'primary' | 'warning' | 'error'
}

const props = withDefaults(defineProps<Props>(), {
    shadow: false,
    sm: false,
    xs: false,
    stylized: undefined,
})

const classes = computed(() => [
    'card',
    {
        'card--shadow': props.shadow,
        'card--sm': props.sm,
        'card--xs': props.xs,
        'card--primary': props.stylized === 'primary',
        'card--warning': props.stylized === 'warning',
        'card--error': props.stylized === 'error',
    },
])
</script>

<template>
    <div :class="classes">
        <slot />
    </div>
</template>
