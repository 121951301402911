import { useRouter } from 'vue-router'
import useMutation from '@/js/hooks/api/useMutation'
import { apiResponseHandler } from '@/js/utils/helpers'
import { IUser } from '@/js/utils/types'
import useUser from '@/js/hooks/useUser'
import { Auth } from '@mumble/api'
import { clearUserLocalStorage } from '@/js/store/UserStore'

export const auth = new Auth('/api/', {
    isSanctum: true,
    enableCredentials: true,
    onUnauthorized: () => {
        if (!window.location.href.includes('accedi')) {
            clearUserLocalStorage()
            window.location.href = '/accedi'
        }
    },
})

export function useLogin() {
    const router = useRouter()
    const { setUser } = useUser()

    const {
        isLoading,
        data: response,
        alert,
        mutate,
    } = useMutation((data) => auth.login(data).then(apiResponseHandler), {
        onSuccess: async (response: IUser) => {
            setUser(response)
            if (response.role === 'company') {
                await router.push({ name: 'company-dashboard', params: { id: response.company_id } })
                return
            }
            if (response.role === 'holding') {
                console.log(response)
                await router.push({ name: 'holding-dashboard', params: { id: response.holding_id } })
                return
            }
            await router.push({ name: 'home' })
        },
    })

    return {
        isLoading,
        response,
        alert,
        mutate,
    }
}

export function useForgotPassword() {
    const { isLoading, alert, mutate } = useMutation((data) => auth.forgotPassword(data))

    return {
        isLoading,
        alert,
        mutate,
    }
}

export function useResetPassword() {
    const { isLoading, alert, mutate } = useMutation((data) => auth.resetPassword(data))

    return {
        isLoading,
        alert,
        mutate,
    }
}

export function useLogout() {
    const router = useRouter()
    const { clearUser } = useUser()

    const { mutate } = useMutation(() => auth.logout(), {
        onSuccess: async () => {
            clearUser()
            await router.push({ name: 'login' })
        },
    })

    return { mutate }
}
