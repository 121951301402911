<script setup lang="ts">
import Layout from '@/views/Layout/Layout.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const route = useRoute()
const isLayoutAuth = computed(() => !route.meta.isProtected)
const hasGradientHeader = computed(() => route.meta.hasGradientHeader)
</script>

<template>
    <router-view v-slot="{ Component }">
        <Layout v-if="!isLayoutAuth" :gradient-header="hasGradientHeader">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </Layout>
        <Layout v-else auth :gradient-header="hasGradientHeader">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </Layout>
    </router-view>
</template>
