import { File } from '@/js/utils/types/common.types'
import { ICompany } from '@/js/utils/types/company.types'
import { IHolding } from '@/js/utils/types/holding.types'

export type Role = 'admin' | 'holding' | 'collaborator' | 'company'

export type IUser = {
    accepted: boolean
    created_at: number
    email: string
    id: number
    updated_at: number
    image: null | File
    role: Role
} & (
    | {
          role: 'admin'
          surname?: string
          name?: string
      }
    | {
          role: 'collaborator'
          surname?: string
          name?: string
      }
    | {
          role: 'holding'
          business_name?: string
          companies?: ICompany[]
          holding_id?: number
      }
    | {
          role: 'company'
          business_name?: string
          company_id?: number
          holding?: IHolding
      }
)

export class User {
    accepted: boolean
    businessName?: string
    createdAt: number
    email: string
    id: number
    name?: string
    role: Role
    surname?: string
    updatedAt: number
    image: null | File
    companies?: ICompany[]
    companyId?: number
    holdingId?: number
    holding?: IHolding

    constructor(payload: IUser) {
        this.accepted = payload.accepted
        this.createdAt = payload.created_at
        this.email = payload.email
        this.id = payload.id
        this.role = payload.role
        this.updatedAt = payload.updated_at
        this.image = payload.image

        if (payload.role === 'admin' || payload.role === 'collaborator') {
            this.surname = payload.surname
            this.name = payload.name
        } else if (payload.role === 'holding') {
            this.businessName = payload.business_name
            this.companies = payload.companies
            this.holdingId = payload.holding_id
        } else {
            this.businessName = payload.business_name
            this.holding = payload.holding
            this.companyId = payload.company_id
        }
    }

    isAdmin() {
        return this.role === 'admin'
    }

    isHolding() {
        return this.role === 'holding'
    }

    isCompany() {
        return this.role === 'company'
    }

    isCollaborator() {
        return this.role === 'collaborator'
    }

    hasPermissions() {
        return this.isAdmin() || this.isCollaborator()
    }

    getFullName() {
        return this.isAdmin()
            ? this.name + ' ' + this.surname
            : this.isCollaborator()
            ? this.name
                ? this.name + ' ' + this.surname
                : this.email
            : this.businessName
    }
}

export type UserFormUpdate = {
    name?: string
    surname?: string
    business_name?: string
    companies_id?: number[]
    holding_id?: number[]
}
