<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    sm?: boolean
    white?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    sm: false,
    white: false,
})

const w = computed(() => (props.sm ? '130' : '176'))
const h = computed(() => (props.sm ? '50' : '69'))
</script>

<template>
    <svg
        :class="['logo', { 'logo--sm': sm, 'logo--white': white }]"
        :width="w"
        :height="h"
        viewBox="0 0 163 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1899_1372)">
            <mask
                id="mask0_1899_1372"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="163"
                height="43"
            >
                <path d="M162.84 0H0V42.51H162.84V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1899_1372)">
                <path
                    d="M75.0498 17.0301V2.62012H80.8798C82.4498 2.62012 83.6698 2.98012 84.5398 3.68012C85.4098 4.39012 85.8498 5.39012 85.8498 6.68012V8.59012C85.8498 9.84012 85.4098 10.8101 84.5398 11.4901C83.6698 12.1701 82.4498 12.5101 80.8798 12.5101H78.1098V17.0301H75.0498ZM78.1098 10.1301H80.4698C81.2998 10.1301 81.8898 9.99012 82.2498 9.70012C82.6098 9.41012 82.7898 8.93012 82.7898 8.24012V6.94012C82.7898 6.24012 82.6098 5.75012 82.2498 5.44012C81.8898 5.14012 81.2998 4.99012 80.4698 4.99012H78.1098V10.1201V10.1301Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M94.9499 17.2101C92.8099 17.2101 91.2699 16.7701 90.3299 15.8901C89.3899 15.0101 88.9199 13.6401 88.9199 11.7901V2.62012H91.9799V11.5401C91.9799 12.6901 92.1799 13.5101 92.5899 13.9901C92.9999 14.4701 93.7799 14.7101 94.9499 14.7101C96.1199 14.7101 96.8999 14.4701 97.3099 13.9901C97.7199 13.5101 97.9199 12.6901 97.9199 11.5401V2.62012H100.98V11.7901C100.98 13.6401 100.51 15.0001 99.5699 15.8901C98.6299 16.7801 97.0899 17.2101 94.9499 17.2101Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M107.64 17.0301H104.58V2.62012H111.78C113.26 2.62012 114.39 2.96012 115.19 3.65012C115.99 4.34012 116.39 5.30012 116.39 6.56012V7.81012C116.39 8.86012 116.12 9.71012 115.58 10.3701C115.04 11.0301 114.26 11.4701 113.24 11.6901L116.81 17.0401H113.32L109.86 11.8501H107.65V17.0401L107.64 17.0301ZM107.64 9.46012H111.13C111.95 9.46012 112.52 9.33012 112.84 9.07012C113.16 8.81012 113.33 8.38012 113.33 7.76012V6.64012C113.33 6.03012 113.17 5.60012 112.84 5.35012C112.52 5.10012 111.95 4.98012 111.13 4.98012H107.64V9.45012V9.46012Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M120.25 17.0301V2.62012H130.62V5.00012H123.31V8.13012H129.72V10.5101H123.31V14.6501H130.62V17.0301H120.25Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M82.3901 40.8001H78.4801L73.8301 26.3901H76.9801L80.5301 38.4001L84.1101 26.3901H87.0501L82.3901 40.8001Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M89.4502 40.8001V38.4201H93.0502V28.7701H89.4502V26.3901H99.7202V28.7701H96.1202V38.4201H99.7202V40.8001H89.4502Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M109.3 40.9602C108.11 40.9602 106.98 40.7802 105.91 40.4102C104.84 40.0402 103.92 39.4802 103.14 38.7102L104.2 36.6402C104.97 37.2902 105.79 37.7702 106.68 38.1002C107.57 38.4302 108.57 38.5902 109.7 38.5902C110.6 38.5902 111.28 38.4302 111.75 38.1002C112.22 37.7802 112.45 37.3102 112.45 36.6902C112.45 36.1402 112.18 35.7402 111.63 35.4902C111.08 35.2402 109.93 34.9702 108.16 34.6702C106.47 34.4102 105.27 33.9802 104.58 33.3802C103.88 32.7902 103.54 31.8902 103.54 30.7102C103.54 29.4302 104.02 28.3602 104.98 27.5102C105.94 26.6602 107.35 26.2402 109.19 26.2402C110.31 26.2402 111.37 26.4202 112.39 26.7902C113.41 27.1602 114.21 27.6602 114.79 28.3102L113.85 30.2902C113.13 29.7402 112.38 29.3302 111.59 29.0402C110.8 28.7502 109.98 28.6102 109.13 28.6102C108.34 28.6102 107.71 28.7502 107.26 29.0302C106.8 29.3102 106.58 29.7502 106.58 30.3402C106.58 30.8902 106.86 31.2902 107.42 31.5302C107.98 31.7702 109.16 32.0502 110.96 32.3602C112.63 32.6502 113.8 33.1002 114.49 33.7102C115.18 34.3202 115.52 35.2202 115.52 36.4102C115.52 37.8402 114.97 38.9502 113.87 39.7402C112.77 40.5302 111.25 40.9302 109.3 40.9302V40.9602Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M118.99 40.8001V38.4201H122.59V28.7701H118.99V26.3901H129.26V28.7701H125.66V38.4201H129.26V40.8001H118.99Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M138.89 40.9802C136.91 40.9802 135.41 40.5402 134.39 39.6702C133.37 38.7902 132.86 37.5502 132.86 35.9402V31.3302C132.86 29.7002 133.37 28.4402 134.4 27.5502C135.43 26.6602 136.93 26.2202 138.91 26.2202C140.89 26.2202 142.39 26.6602 143.4 27.5502C144.41 28.4402 144.92 29.7002 144.92 31.3302V35.9402C144.92 37.5502 144.41 38.7902 143.39 39.6702C142.37 40.5502 140.87 40.9802 138.89 40.9802ZM138.89 38.6002C139.97 38.6002 140.74 38.3802 141.19 37.9402C141.64 37.5002 141.86 36.7502 141.86 35.6802V31.5702C141.86 30.4802 141.64 29.7102 141.19 29.2602C140.74 28.8102 139.97 28.5802 138.89 28.5802C137.81 28.5802 137.04 28.8102 136.59 29.2602C136.14 29.7102 135.92 30.4802 135.92 31.5702V35.6802C135.92 36.7502 136.14 37.5002 136.59 37.9402C137.04 38.3802 137.81 38.6002 138.89 38.6002Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M156.37 40.8001L151.18 29.5801V40.8001H148.51V26.3901H152.74L157.93 37.6501V26.3901H160.58V40.8001H156.37Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M21.26 1.25C32.31 1.25 41.27 10.21 41.27 21.26C41.27 32.31 32.31 41.27 21.26 41.27C10.21 41.27 1.25 32.31 1.25 21.26C1.25 10.21 10.21 1.25 21.26 1.25Z"
                    stroke="#1D1D1B"
                    stroke-width="2.5"
                />
                <path
                    d="M57.8801 12.98C62.4501 12.98 66.1601 16.69 66.1601 21.26C66.1601 25.83 62.4501 29.54 57.8801 29.54C53.3101 29.54 49.6001 25.83 49.6001 21.26C49.6001 16.69 53.3101 12.98 57.8801 12.98Z"
                    stroke="#1D1D1B"
                    stroke-width="2.5"
                />
            </g>
            <path d="M19.73 21.2598H49.75" stroke="#1D1D1B" stroke-width="2.08" />
        </g>
        <defs>
            <clipPath id="clip0_1899_1372">
                <rect width="162.84" height="42.51" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
